import {
    CRMFieldSuggestionAPIRepresentation,
    CRMFieldSuggestionDTO,
    FieldAPIRepresentation,
    FieldDTO,
    MappingAPIRepresentation,
    MappingDTO,
    ObjectAPIRepresentation,
} from '../../../types/MappingDTO';
import { get, patch } from '../../../global/connectors/BridgeAPIConnector';

export async function getMappingById(companyId: string, mappingId: string): Promise<MappingDTO> {
    const mappings = await get(`/companies/${companyId}/mappings`);
    const mappingFound = mappings.find((mapping: MappingAPIRepresentation) => mapping.id === mappingId);
    const fields: FieldDTO[] = [];
    mappingFound.fields?.forEach((field: FieldAPIRepresentation) => {
        fields.push({
            crmField: field.crm_field,
            badgerField: field.badger_field,
            type: field.type,
        });
    });
    return {
        id: mappingFound.id,
        badgerName: mappingFound.badger_object_name,
        crmName: mappingFound.crm_object_name,
        fields: fields,
        customJS: mappingFound.custom_js,
    };
}

export async function updateMapping(companyId: string, mapping: MappingDTO): Promise<void> {
    const fields: FieldAPIRepresentation[] = [];
    mapping.fields?.forEach((field: FieldDTO) => {
        fields.push({
            crm_field: field.crmField,
            badger_field: field.badgerField,
            type: field.type,
        });
    });
    await patch(`/companies/${companyId}/mappings/${mapping.id}`, {
        badger_object_name: mapping.badgerName,
        crm_object_name: mapping.crmName,
        fields: fields,
        custom_js: mapping.customJS,
    });
}

export async function getFieldSuggestions(companyId: string, objectName: string): Promise<CRMFieldSuggestionDTO[]> {
    const users = await get(`/companies/${companyId}/connection-health/`);
    const connectedUsers = users.filter((user: any) => user.connection_health === true);

    if (connectedUsers.length === 0) {
        console.log('No connected users found');
        return [];
    }

    const fields: CRMFieldSuggestionAPIRepresentation[] = await get(
        `/users/${connectedUsers[0].user_id}/metadata/objects/${objectName}/fields`
    );
    if (!fields.length) {
        return [];
    }
    const fieldsDTOs: CRMFieldSuggestionDTO[] = [];
    fields?.forEach((object: ObjectAPIRepresentation) => {
        fieldsDTOs.push({
            name: object.name,
            apiName: object.api_name,
        });
    });
    return fieldsDTOs;
}
