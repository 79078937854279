import { enqueueSnackbar } from 'notistack';

export const BACKEND_HOST =
    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACKEND_HOST : 'http://localhost:8000';
const authToken = document.cookie
    .split(';')
    .find((cookie) => cookie.includes('badgerToken'))
    ?.split('=')[1];

export async function get(path: string): Promise<any> {
    const url = `${BACKEND_HOST}${path}`;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        credentials: 'include',
        redirect: 'follow',
    });
    const data = await response.json();
    const errorMessage = data?.message || response.statusText;
    if (response.status > 400) {
        enqueueSnackbar(`Something went wrong! Message: ${errorMessage}`, { variant: 'error' });
    }
    return data?.items ? data.items : data;
}

export async function post(path: string, data: any): Promise<any> {
    const url = `${BACKEND_HOST}${path}`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        credentials: 'include',
        body: JSON.stringify(data),
        redirect: 'follow',
    });
    const responseData = await response.json();
    const errorMessage = responseData?.message || response.statusText;
    if (response.status < 400) {
        enqueueSnackbar('Created successfully', { variant: 'success' });
    } else {
        enqueueSnackbar(`Something went wrong! Message: ${errorMessage}`, { variant: 'error' });
    }
    return responseData;
}

export async function patch(path: string, data: any): Promise<any> {
    const url = `${BACKEND_HOST}${path}`;
    const response = await fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        credentials: 'include',
        body: JSON.stringify(data),
        redirect: 'follow',
    });
    const responseData = await response.json();
    if (response.status < 400) {
        enqueueSnackbar('Updated successfully', { variant: 'success' });
    } else {
        const errorMessage = data?.message || response.statusText;
        enqueueSnackbar(`Something went wrong! Message: ${errorMessage}`, { variant: 'error' });
    }
    return responseData.items;
}

export async function remove(path: string): Promise<any> {
    const url = `${BACKEND_HOST}${path}`;
    const response = await fetch(url, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
        redirect: 'follow',
    });
    const data = await response.json();
    if (response.status < 400) {
        enqueueSnackbar('Deleted successfully', { variant: 'success' });
    } else {
        const errorMessage = data?.message || response.statusText;
        enqueueSnackbar(`Something went wrong! Message: ${errorMessage}`, { variant: 'error' });
    }
    return data?.items;
}
